import React from 'react';

import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { Button, Form, Grid } from 'semantic-ui-react';
import { isEmpty } from 'lodash';

import Actions from 'src/state/root-actions';

import { getErrorMessage, hasAllValues } from 'src/utils/form-utils';

const ForgotPasswordForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    buttonLabel,
}) => {
    return (
        <Form onSubmit={handleSubmit} size="large" noValidate>
            <h2 style={{ color: 'red' }}>{errors.general}</h2>

            <Grid centered columns={2}>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Input
                            id="login-email"
                            name="email"
                            type="email"
                            label={{
                                children: 'Email',
                                htmlFor: 'login-email',
                                className: values.email ? '' : 'hidden',
                            }}
                            placeholder="Email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            error={
                                errors.email && touched.email
                                    ? getErrorMessage(errors.email)
                                    : false
                            }
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Button
                            type="submit"
                            disabled={
                                isSubmitting ||
                                !hasAllValues(values) ||
                                !isEmpty(errors)
                            }
                            className="margin-top--small"
                            primary
                            fluid
                        >
                            {buttonLabel}
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

const handleSubmit = (formValues, { props, setSubmitting, setFieldError }) => {
    const onFailure = (message) => setFieldError('general', message);
    const onFulfill = () => setSubmitting(false);
    const onSuccess = () => props.onSubmit(formValues.email);
    props.dispatch(
        Actions.auth.forgotPassword.trigger({
            ...formValues,
            onFailure,
            onSuccess,
            onFulfill,
        })
    );
};

const FormikForgotPasswordForm = withFormik({
    handleSubmit,
    mapPropsToValues: (props) => ({
        email: props.email || '',
    }),
})(ForgotPasswordForm);

export default connect()(FormikForgotPasswordForm);
