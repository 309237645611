import React, { useState } from 'react';
import { Link } from 'gatsby';
import { connect } from 'react-redux';
import { Button, Container, Grid, Icon, Image } from 'semantic-ui-react';

import ForgotPasswordForm from 'src/components/auth/ForgotPasswordForm';
import ButtonLink from 'src/components/shared/ButtonLink';
import SEO from 'src/components/SEO';
import Actions from 'src/state/root-actions';
import AuthPageWrap from 'src/components/auth/AuthPageWrap';

const LoginHelpPage = ({ location, dispatch }) => {
    const [isEmailSent, setIsEmailSent] = useState(false);
    const [email, setEmail] = useState();
    const submitEmail = (email) => {
        setIsEmailSent((showSentEmailState) => !showSentEmailState);
        setEmail(email);
    };

    // TODO: Ask ashley on what to do for resend email onSuccess and onError
    const onFailure = (message) => console.log(message);

    const handleClick = () => {
        dispatch(
            Actions.auth.forgotPassword.trigger({
                email,
                onFailure,
            })
        );
    };

    return (
        <AuthPageWrap>
            <SEO title="P.Volve Reset Password" />
            <Image
                src="https://cdn.shopify.com/s/files/1/2379/5349/files/pvolve-logo.jpg"
                size="small"
                centered
            />
            {isEmailSent ? (
                <>
                    <Container textAlign="center">
                        <h2 className="margin-top--small">Check your email.</h2>
                    </Container>
                    <Grid centered columns={3}>
                        <Grid.Row>
                            <Grid.Column>
                                <p className="secondaryText margin-top--small">
                                    An email was sent to {email}.
                                </p>
                                <p className="secondaryText margin-top--small">
                                    Click the link in the eamil to reset your
                                    password.
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Button as={Link} to="/continue" primary fluid>
                                    GOT IT
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <p className="secondaryText margin-top--small">
                                    Didn't get it? Check your spam or junk
                                    folder. If it's still not there,{' '}
                                    <ButtonLink
                                        role="button"
                                        className="resend-email"
                                        onClick={handleClick}
                                    >
                                        resend email
                                    </ButtonLink>
                                    .
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </>
            ) : (
                <>
                    <Container textAlign="center">
                        <h2 className="margin-top--small">
                            We've all been there
                        </h2>
                        <p className="secondaryText margin-top--small">
                            Enter your account email, and we'll send you an
                            email to reset your password
                        </p>
                    </Container>
                    <div className="margin-top--large">
                        <ForgotPasswordForm
                            email={location?.state?.email}
                            buttonLabel="SEND EMAIL"
                            onSubmit={submitEmail}
                        />
                    </div>
                    <Container textAlign="center">
                        <p className="margin-top--xlarge">
                            <Link to="/continue">
                                <Icon name="arrow left" />
                                <b>I remember now. Go back.</b>
                            </Link>
                        </p>
                    </Container>
                </>
            )}
        </AuthPageWrap>
    );
};

export default connect()(LoginHelpPage);
